/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:a6073076-3319-406b-99b2-6b4ea0f4c4a3",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_FluzHLTqE",
    "aws_user_pools_web_client_id": "18qjiepgqp5mj9tmkb412b44fq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ukc-owcat-files112457-develop",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "3564cba5c1034fc4a1c9804fd52fe294",
    "aws_mobile_analytics_app_region": "eu-west-2"
};


export default awsmobile;
